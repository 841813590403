.gallery {
  display: flex;
  flex-wrap: wrap;
  /* Compensate for excess margin on outer gallery flex items */
  margin: -1rem -1rem;
}

.gallery-item {
  /* Minimum width of 24rem and grow to fit available space */
  flex: 1 0 12rem;
  /* Margin value should be half of grid-gap value as margins on flex items don't collapse */
  margin: 1rem;
  box-shadow: 0.3rem 0.4rem 0.4rem rgba(0, 0, 0, 0.4);
  overflow: hidden;
}

.gallery-image {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 400ms ease-out;
}

.gallery-image:hover {
  transform: scale(1.15);
}

.protfolio__container p{
  width: 85%;
}

/*

The following rule will only run if your browser supports CSS grid.

Remove or comment-out the code block below to see how the browser will fall-back to flexbox styling. 

*/

@supports (display: grid) {
  .gallery {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
    grid-gap: 2rem;
  }

  .gallery,
  .gallery-item {
    margin: 0;
  }
  .video-viewer img {
    grid-template-columns: repeat(auto-fit, minmax(12rem, 1fr));
    grid-gap: 1rem;
  }
}

.portfolifo-viewer {
  display: flex;
  flex-direction: column;
  background-color: #f4fcff;
  padding: 30px;
  margin: 20px;
  margin-top: 100px;
  align-items: center;
}

.portfolifo-view-title {
  font-weight: 600;
  color: #659db4;
  font-size: 2em;
  padding-bottom: 20px;
}

.title-bar {
  width: 75px;
  height: 3px;
  border-radius: 5px;
  background-color: #ffc107;
}

.video-list {
  display: flex;
}

.video-viewer {
  margin: 15px;
  cursor: pointer;
}

.embedsocial-hashtag {
  width: 80%;
  margin: 0 auto;
}

.portfolifo-viewer .coming-soon{
  background-color: var(--black);
  color: var(--white);
  margin-top: 50px;
  padding: 30px;
}


@media (max-width: 575.98px) {
  @supports (display: grid) {
    .gallery {
      grid-template-columns: repeat(auto-fit, minmax(7rem, 1fr));
    }

    .video-viewer img {
      grid-template-columns: repeat(auto-fit, minmax(7rem, 1fr));
    }
  }
}
