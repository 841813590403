.Book__Now__Container{
    display: flex;
    justify-content: center;
    margin: 20px;
}
.Image__Container{
    width: 500px;
    margin-top: 60px;
}

.Image__Container img{
    object-fit: cover;
}


.Contact__Details{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    color: #659db4;
    width: calc(100% - 55%);
    margin-top: 60px;
}

.title{
    font-weight: 600;
    font-size: 2em;
    padding-bottom: 10px;
    padding-top: 10px;
    text-transform: uppercase;
}
.title-bar{
    width: 75px;
    height: 3px;
    border-radius: 5px;
    background-color: #ffc107;
}
.Book__Form__Container{
    margin-top: 40px;
}
form{
    width: 100%;
    text-align: left;
}
.book-now-btn{
    color: #212529 !important;
    background-color: #ffc107 !important;
    border-color: #ffc107 !important;
}
.react-datepicker-wrapper, .react-datepicker__input-container{
    width: 100%;
}
.Book__Now__Container .form-control{
    background-color: var(--light-blue);
    border: 1px solid #5AC7FF;
    border-radius: 5px;
    font-size: 0.9rem;
    font-weight: bold;
    padding: 20px;
}

.Book__Now__Container .FormSelect {
    background-color: var(--light-blue);
    border: 1px solid #5AC7FF;
}

.conditions-booknow{
    font-size: 0.7rem;
}

@media (max-width: 575.98px) {
    .Image__Container{
        display: none;
    }
    .Contact__Details{
        width: 100%;
    }
}