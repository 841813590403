  .navbar .navbar-brand {
    font-size: 0.9rem;
  }
  .navbar .nav-item {
    padding: 10px 20px;
  }
  .navbar .nav-link {
    font-size: 0.9rem;
    margin-left: 10px;
  }
  
  .active {
    background-color: #F6CFCF;
    color: #3B7796;
    height: 30px;
  }

  .btn:hover{
    background-color: #F6CFCF;
  }

  /* for mobile screen view */
@media (max-width: 575.98px) {

}