@import url('/node_modules/react-modal-video/css/modal-video.min.css');

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes slide-down {
  from {
    opacity: 0;
    transform: translateY(-50px);
  }
  to {
    opacity: 1;
    transform: none;
  }
}

@keyframes slide-up {
  from {
    opacity: 0;
    transform: translateY(50px);
  }
  to {
    opacity: 1;
    transform: none;
  }
}


/* Futura-medium - latin */
@font-face {
  font-family: 'Futura_Medium';
  font-style: normal;
  font-weight: 800;
  src: url('./Fonts/Futura_WebFont/Futura-Medium.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./Fonts/Futura_WebFont/Futura-Medium.eot') format('embedded-opentype'), /* IE6-IE8 */
       url('./Fonts/Futura_WebFont/Futura-Medium.woff') format('woff'), /* Modern Browsers */
       url('./Fonts/Futura_WebFont/Futura-Medium.ttf') format('truetype'), /* Safari, Android, iOS */
}

/* Futura-bold - latin */
@font-face {
  font-family: 'Futura_Bold';
  font-style: normal;
  font-weight: 800;
  src: url('./Fonts/Futura_WebFont/Futura-Bold.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./Fonts/Futura_WebFont/Futura-Bold.eot') format('embedded-opentype'), /* IE6-IE8 */
       url('./Fonts/Futura_WebFont/Futura-Bold.woff') format('woff'), /* Modern Browsers */
       url('./Fonts/Futura_WebFont/Futura-Bold.ttf') format('truetype'), /* Safari, Android, iOS */
}

/* raleway-regular - latin */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 400;
  src: url('./Fonts/Raleway_WebFont/raleway-v22-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./Fonts/Raleway_WebFont/raleway-v22-latin-regular.eot') format('embedded-opentype'), /* IE6-IE8 */
       url('./Fonts/Raleway_WebFont/raleway-v22-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('./Fonts/Raleway_WebFont/raleway-v22-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('./Fonts/Raleway_WebFont/raleway-v22-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./Fonts/Raleway_WebFont/raleway-v22-latin-regular.svg#Raleway') format('svg'); /* Legacy iOS */
}
/* raleway-600 - latin */
@font-face {
  font-family: 'Raleway_Medium';
  font-style: normal;
  font-weight: 600;
  src: url('./Fonts/Raleway_WebFont/raleway-v22-latin-600.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./Fonts/Raleway_WebFont/raleway-v22-latin-600.eot') format('embedded-opentype'), /* IE6-IE8 */
       url('./Fonts/Raleway_WebFont/raleway-v22-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
       url('./Fonts/Raleway_WebFont/raleway-v22-latin-600.woff') format('woff'), /* Modern Browsers */
       url('./Fonts/Raleway_WebFont/raleway-v22-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./Fonts/Raleway_WebFont/raleway-v22-latin-600.svg#Raleway') format('svg'); /* Legacy iOS */
}
/* raleway-900 - latin */
@font-face {
  font-family: 'Raleway_Bold';
  font-style: normal;
  font-weight: 900;
  src: url('./Fonts/Raleway_WebFont/raleway-v22-latin-900.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./Fonts/Raleway_WebFont/raleway-v22-latin-900.eot') format('embedded-opentype'), /* IE6-IE8 */
       url('./Fonts/Raleway_WebFont/raleway-v22-latin-900.woff2') format('woff2'), /* Super Modern Browsers */
       url('./Fonts/Raleway_WebFont/raleway-v22-latin-900.woff') format('woff'), /* Modern Browsers */
       url('./Fonts/Raleway_WebFont/raleway-v22-latin-900.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./Fonts/Raleway_WebFont/raleway-v22-latin-900.svg#Raleway') format('svg'); /* Legacy iOS */
}

:root {
  --white: #FFFFFF;
  --black: #000000;
  --very-light-grey: #E2E2E2;
  --light-grey: #B4B4B4;
  --grey: #8E8E8E;
  --dark-grey: #242424;
  --dark-blue: #3B7796;
  --light-blue: #F4FCFF;
  --yellow: #FBC817;
  --light-green: #9BD13B;
  --dark-green: #61891A;
  --boxShadow: rgba(0, 0, 0, 0.1);
  --fontRaleway: "Raleway", sans-serif;
  --fontRalewayBold: 'Raleway_Bold', sans-serif;
  --fontRalewayMedium: 'Raleway_Medium', sans-serif;
  --fontRalewayLight: 'Raleway_Light', sans-serif;
  --fontFuturaBold: 'Futura_Bold', sans-serif;
  --fontFuturaMedium: 'Futura_Medium', sans-serif;


  --font-family-primary: "Raleway",serif;
  --font-family-secondary: "Futura",sans-serif;
  --font-light: 300;
  --font-normal: 400;
  --font-medium: 500;
  --font-semibold: 600;
  --font-bold: 700;
  --font-extra-bold: 800;
  --font-extra-extra-bold: 900;
}

* {
  margin: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

body {
  font-family: var(--fontMaven);
  font-weight: normal;
  font-style: normal;
  color: var(--dark-grey);
  overflow-X: hidden;
  background-color: var(--white);
}


.yellow-stroke{
  background-color: var(--yellow);
  height : 0.35rem;
  width : 90px;
}