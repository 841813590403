.bg-overlay {
    background: linear-gradient(rgba(0,0,0,.2), rgba(0,0,0,.5)), url("../../images/services.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    color: #fff;
    height: 90vh;
    padding-top: 50px;
}


.services-banner-section h1{
font-family: "Raleway_Bold";
font-size: 6rem;
font-weight: bold;
letter-spacing: 20px;
margin-top: 10%;
animation: slide-up 1.5s forwards;
}

.services-banner-section p{
    font-family: "Futura";
    font-size: 1rem;
    margin-top: 40px;
    animation: slide-up 1.9s forwards;
}


.our-services-section{
background-color: var(--light-blue);
}

.our-services-section img{
    width: 40px;
}

.our-services-section h2{
    font-family: var(--fontRalewayBold);
    font-size: 2rem;
    margin-top: 40px;
}

.our-services-section h3{
    font-family: var(--fontRaleway);
    font-size: 1.3rem;
    font-weight: 700;
    color: var(--dark-blue);
}

.our-services-section p{
    font-family: var(--fontFuturaLight);
    font-size: 0.9rem;
    font-weight: 500;
    color: var(--grey);
}


.our-pricing-section img{
    width: 50px;
}

.our-pricing-section h2{
    font-family: var(--fontRalewayBold);
    font-size: 2rem;
}

.our-pricing-section .box-card{
   border-radius: 5px;
   padding: 20px;
   animation: slide-up 1.5s forwards;
}

.blue-box{
    border: 2px solid #71D9FF;
    background-color: #F0FBFF;
}

.orange-box{
    border: 2px solid #718bff;
    background-color: #f0f4ff;
    
}


.box-card h2 span{
    background-color: var(--dark-blue);
    color: var(--white);
    font-weight: 700;
    font-size: 1.5rem;
    padding: 5px 15px;
}

.box-card .small-size{
    font-size: 0.7rem;
}
.box-card p {
    margin-bottom: 5px;
    margin-top: 20px;
}

.box-card p, .box-card span{
    color: var(--dark-blue);
}

.box-card hr{
    border-top: 1px solid rgb(119 209 255 / 50%);
}

.our-pricing-section .btn-active{
    color: #fff;
    background-color: var(--dark-blue);
    border: none;
    border-radius: 4px;
    padding: 20px 30px;
    font-weight: 800;
}

.our-pricing-section .btn-inactive{
    color: #8E8E8E;
    background-color: none;
    border: none;
    padding: 20px 30px;
    font-weight: 800;
}
.our-pricing-section .services-info h4{
    color: var(--dark-blue);
    font-size: 0.9rem;
}

.our-pricing-section .services-info p{
    color: var(--dark-grey);
    font-size: 0.9rem;
}

/* for mobile screen view */
@media (max-width: 575.98px) {
  
    .services-banner-section h1 {
        margin-top: 100px;
        font-size: 2rem;
    } 
    .services-banner-section p{
        font-size: 0.8rem;
    }

    .our-pricing-section .btn-active{
       
        width: 130px;
    }
    
    .our-pricing-section .btn-inactive{
       
        width: 130px;
    }
    
}
