.courses-banner-section{
    background-image: url(../../images/banner.png);
    background-size: cover;
    background-position: center;
}

.courses-banner-section img{
    width : 500px;
    animation: slide-down 1.5s forwards;
}

.courses-banner-section h1{
    font-family: var(--fontRalewayBold);
    font-size: 6rem;
    color: var(--white);
    font-weight: bold;
    animation: slide-up 1.5s forwards;
}

.courses-banner-section p{
    font-family: var(--fontRaleway);
    font-size: 0.9rem;
    color: var(--white);
    animation: slide-up 1.9s forwards;
}
.courses-banner-section .bold-text{
    letter-spacing: 14px;
}
.courses-banner-section button{
    height: 40px;
    color: var(--white);
    border-color: var(--white);
    border-radius: 0;
}


.upcomingcourses-section{
    background-color: var(--light-blue);
    }

.upcomingcourses-section p span{
    background-color: var(--black);
    color: var(--white);
    margin-top: 50px;
    padding: 30px;
}


/* for mobile screen view */
@media (max-width: 575.98px) {
    .courses-banner-section img{
        width : 200px;
        margin-top: 50px;
    }

    .courses-banner-section h1{
        margin-top: 30px;
        font-size: 3rem;
    }
    
    .courses-banner-section p{
        font-size: 0.8rem;
    }
}