.homebanner-section img{
    height : 600px;
    animation: slide-down 1.5s forwards;
}

.personals-section img{
    height : 400px
}

.homebanner-section{
    background-image: url(../../images/banner.png);
    background-size: cover;
    background-position: center;
    height: 100vh;
}

.homebanner-section h1{
    font-family: var(--fontRalewayBold);
    font-size: 7rem;
    color: var(--white);
    font-weight: bold;
    animation: slide-up 1.5s forwards;
}

.homebanner-section p{
    font-family: var(--fontRaleway);
    font-size: 1.1rem;
    color: var(--white);
}
.homebanner-section .bold-text{
    letter-spacing: 14px;
}
.homebanner-section button{
    height: 40px;
    color: var(--white);
    border-color: var(--white);
    border-radius: 0;
}

.personals-section{
    background-color: var(--light-blue);
    }

    .personals-section p {
        font-family: var(--fontFuturaMedium);
    }
    
    .personal-section h3{
        font-family: var(--fontRalewayBold);
    }
.link-section .box-link{
    background-color: #124B64;
    box-shadow: 3px 6px 24px var(--boxShadow);
    color: var(--white);
}

.box-link:hover{
 background-color: var(--yellow);
 cursor: pointer;
}

.link-section .box-link img{
    width : 20px;
}

.link-section .box-link p,.link-section .box-link h2{
    color: var(--white);
    padding-bottom: 10px;
}

/* for mobile screen view */
@media (max-width: 575.98px) {
    .homebanner-section img{
    height : 260px;
}

.personals-section img{
    height : 290px
}

.homebanner-section h1{
   font-size: 3rem;
}

.outline-text{
    margin-top: 90px;
    letter-spacing: 14px;
    background-color: var(--yellow);
}

.link-section .box-link{
    margin: 20px 0;
}
  }
