.social-media img{
height: 30px;
margin-right: 20px;
}

.book-now{
    color: var(--dark-grey);
    background-color: var(--yellow);
    border : none;
    height : 30px;
}

.footer-id p{
    font-size: 0.8rem;
}

.footer-id .links{
    color: var(--white);
    font-size: 0.8rem;
    text-decoration: underline;
}

.confit-email:before {
	content: "\f0e0";
	margin-right:5px;
	font-family: "FontAwesome";
}


.confit-phone:before {
	content: "\f095";
	margin-right:5px;
	font-family: "FontAwesome";
}

.confit-address:before {
	content: "\f2bb";
	margin-right:5px;
	font-family: "FontAwesome";
}
