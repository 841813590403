.contactus-section input, .contactus-section textarea{
    background-color: var(--light-blue);
    border: 1px solid #5AC7FF;
    border-radius: 5px;
    font-size: 0.9rem;
    font-weight: bold;
    padding: 20px;
}

.contactus-section {
    font-size: 0.9rem;
    font-weight: bold;
    color: var(--dark-blue);
    padding: 20px;
}

.contactus-section img{
    width : 520px;
}

.contactus-section h2,.subscribe-section h2{
    font-family: var(--fontRalewayBold);
}

.subscribe-section h2{

}

.subscribe-section{
    background-image: url(../../images/subscribe.png) ;
    background-image: -moz-linear-gradient(left bottom, rgb(227, 227, 227) 31%, rgb(199, 199, 199) 66%, rgb(184, 184, 184) 83%);
  
    background-size: cover;
    background-position: center;
}

/* for mobile screen view */
@media (max-width: 575.98px) {
    .contactus-section img{
        width : 220px;
    }

}