#bio-id .bio-banner-section{
    background-color: var(--light-blue);
    background-size: cover;
    background-position: center;
    height: 100vh;
    font-family: var(--fontRalewayBold);
}

#bio-id h3{
    font-family: var(--fontRalewayBold);
}

.bio-banner-section img{
    height : 600px;
    animation: slide-down 1.5s forwards;
}

.bio-banner-section h1{
    font-family: var(--fontRalewayBold);
    font-size: 7rem;
    color: var(--dark-grey);
    font-weight: bold;
    animation: slide-up 1.5s forwards;
}

.bio-banner-section p{
    font-family: var(--fontRaleway);
    font-size: 1.1rem;
    color: var(--dark-grey);
}
.bio-banner-section .bold-text{
    letter-spacing: 14px;
}
.bio-banner-section button{
    height: 40px;
    color: var(--dark-grey);
    border-color: var(--dark-grey);
    border-radius: 0;
    font-family: var(--fontFuturaMedium);
}

.banner-info{
}

.personal-section{
    background-color: var(--light-blue);
}

.workfolio-section{
    background-color: var(--white);
}

.workfolio-section img, .personal-section img{
    width: 500px;
}

.workfolio-section p,.personal-section p{
    color : var(--grey);
    font-family: var(--fontFuturaMedium);

}

.reviews-section p {
        color: var(--grey);
        font-family: var(--fontFuturaMedium);
}

.reviews-section img{
    width: 100px;
}

.reviews-section .round-display{
    flex-direction: column;
}



/* for mobile screen view */
@media (max-width: 575.98px) {
    .bio-banner-section h1{
        font-size: 3rem;
     }
    .bio-banner-section img{
    height : 260px;
    }

    .outline-text{
        margin-top: 90px;
        letter-spacing: 14px;
        background-color: var(--yellow);
        font-size: 3rem;
    }

    .reviews-section{
        background-color: var(--light-blue);
    }
   
    .reviews-section .round-display{
        flex-direction: row;
    }

    .reviews-section img{
        width : 60px;
    }

    .workfolio-section img,.personal-section img{
        width: 250px;
    }
}


.bio-gallery {
	display: flex;
	flex-wrap: wrap;
	/* Compensate for excess margin on outer bio-gallery flex items */
	margin: -1rem -1rem;
}

.bio-gallery-item {
	/* Minimum width of 24rem and grow to fit available space */
	flex: 1 0 8rem;
	/* Margin value should be half of grid-gap value as margins on flex items don't collapse */
	margin: 1rem;
	box-shadow: 0.3rem 0.4rem 0.4rem rgba(0, 0, 0, 0.4);
	overflow: hidden;
    height: 200px;
}

.bio-gallery-image {
	display: block;
	width: 100%;
	height: 100%;
	object-fit: cover;
	transition: transform 400ms ease-out;
}

.bio-gallery-image:hover {
	transform: scale(1.15);
}

@supports (display: grid) {
	.bio-gallery {
		display: grid;
		grid-template-columns: repeat(auto-fit, minmax(8rem, 1fr));
		grid-gap: 2rem;
	}

	.bio-gallery,
	.bio-gallery-item {
		margin: 0;
	}
}